import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';


const Kataloq = () => {

  const { t, i18n } = useTranslation();

  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [category, setCategory] = useState('')
  const { name, brand } = useParams();


  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.agrogurama.az/agrogurama/products.php?category=${encodeURIComponent(name)}`,
        { withCredentials: false }
      );
  
       console.log(response.data.data);

        const filteredProducts = response.data.data.filter(product => product.brand == brand);
        setProducts(filteredProducts);
        console.log(filteredProducts)
        setIsLoading(false)
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if(name == 'traktor') {
     setCategory("Traktorlar")
    }
    if(name == 'aqreqat') {
      setCategory("Aqreqatlar")
     }
     if(name == 'land') {
      setCategory("Torpaq işləmə avadanlığı")
     }

     if(name == 'garden') {
      setCategory("Bağçılıq")
     }
     if(name == 'harvest') {
      setCategory("Məhsul yığım avadanlığı")
     }
     if(name == 'press') {
      setCategory("Pressbağlayıcı")
     }
     if(name == 'dirmiq') {
      setCategory("Dırmıq")
     }
     if(name == 'otbicen') {
      setCategory("Otbiçən")
     }
     if(name == 'kombayn') {
      setCategory("Kombayn")
     }
  }, [])
  
  

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className="mt-[40px] max-w-containerSm md:max-w-container mx-auto mb-[40px] md:mb-[132px]">
         <div className='flex flex-col justify-center items-center mb-10'>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center">{category}</p>
         </div>
        <div>
      <div className='grid md:grid-cols-4 grid-cols-1 gap-4'>
        {products.map((val, index) => { 
          const { image, category, name, path, content, id, shortDescription} = val;
          console.log(id)
          return (
            <a href={`/${category}/company/${brand}/${id}`}>
              <div  data-aos="fade-up" className='bg-[#f5f9ee] rounded-[20px] h-[291px] ' key={index}>
              
              <div className='pt-3 pb-5 pl-4 pr-4'>      
              <div className='h-[171px] md:w-[266px] bg-[white] rounded-lg flex justify-center items-center'>
              <img className='rounded-lg h-[171px]' src={`https://www.agrogurama.az/agrogurama/${image}`} alt={name} />
              </div>
               <div className='text mt-3'>
                 <h4 className='text-black text-base font-medium mb-2'>{t(name)}</h4>
                 <h4 className='text-[black] text-sm font-normal'>{t(shortDescription)}</h4>
               </div>
              </div>
             </div> 
            </a>
          ); 
        })}
      </div>

        </div>
      </div>
  );
};

export default Kataloq;
















 // if (Array.isArray(response.data)) {
      //   const filteredProducts = response.data.filter(product => product.brand == brand);
      //   setProducts(filteredProducts);
      //   console.log(filteredProducts)
      //   setIsLoading(false)
      // } else {
      //   console.error("Data is not an array:", response.data);
      // }