import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, aboutHome, hero } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const About = () => { 
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  
  return (
    <div className="md:max-w-container max-w-containerSm mt-[40px] md:mt-[105px] mx-auto">
               <div className='flex flex-col justify-center items-center mb-5 md:mb-10'>
         <button className="w-[120px] bg-[#d4d64f] rounded-[32px] text-[white]">Haqqımızda</button>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center ">Kənd Təsərrüfatı Sektorunda Artan Trend</p>
         </div>
    <p className="text-center text-black text-base font-normal mb-5">“AGRO GURAMA”MMC Şirkəti 2022ci ildən fəaliyyət göstərir və kənd  təsərüffatı texnikaları təhcizatçıları sırasında önəmli mövqe tutmuşdur. Biz fermerlərin istəklərinə uyğun olaraq zamanın tələblərinə cavab verən müasir innovativ texnikaları fermerlərin və təsərüffatların əlçatanlığını təmin etmək məqsədi ilə ölkəmizə idxalını təmin edirik.“AGRO GURAM”MMC Şirkəti 2022ci ildən fəaliyyət göstərir və kənd  təsərüffatı texnikaları təhcizatçıları sırasında önəmli mövqe tutmuşdur</p>
    <div
  style={{
    backgroundImage: `url(${aboutHome})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '450px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Dikeyde ortalar
    alignItems: 'center', // Yatayda ortalar
    textAlign: 'center',
    borderRadius:'20px'
  }}
>
  <p className='text-center text-white text-[22px] md:text-[32px] font-medium md:font-bold md:w-[1016px]'>
    AgroGurama ilə əməkdaşlıq edərək kənd təsərrüfatında yüksək nəticələr əldə edin!
  </p>
 <a href='/about'>
 <button className='w-36 h-10 rounded-[14px] border border-[#0d4b2c] mt-5 text-[white]'>
    Daha ətraflı
  </button>
 </a>
</div>

     </div>
  
  );
};

export default About;
