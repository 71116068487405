import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, blog1, blog2, blog3, blogDetail, cardReader, dataTerminal, pulQutulari } from '../../assets/index';
import { useTranslation } from 'react-i18next';


export const products = [
  {
    image: blog1,
    name: "Kartof əkini və əkində texnikanın rolu",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog2,
    name: "Təsərrüfatınıza uyğun traktorlar",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog3,
    name: "Düzgün suvarma sisteminin qurulm...",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog1,
    name: "Kartof əkini və əkində texnikanın rolu",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog2,
    name: "Təsərrüfatınıza uyğun traktorlar",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog3,
    name: "Düzgün suvarma sisteminin qurulm...",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog1,
    name: "Kartof əkini və əkində texnikanın rolu",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog2,
    name: "Təsərrüfatınıza uyğun traktorlar",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog3,
    name: "Düzgün suvarma sisteminin qurulm...",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog1,
    name: "Kartof əkini və əkində texnikanın rolu",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog2,
    name: "Təsərrüfatınıza uyğun traktorlar",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog3,
    name: "Düzgün suvarma sisteminin qurulm...",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },


];

const BlogDetail = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className="mt-[40px] max-w-containerSm md:max-w-container mx-auto mb-[40px] md:mb-[132px]">
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center mb-[22px]">Qusar 2023 Toxum Sərgi-Satış Yarmarkası</p>
         <p className='mb-8 text-center md:text-left'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut consectetur, elit vel porta scelerisque, nibh enim consequat justo, non placerat nisi leo sed felis. Suspendisse sed ultricies nulla, et euismod orci. In vitae purus tristique purus blandit eleifend. Phasellus tincidunt risus nec nisi dictum mattis. Nam a tellus a augue molestie accumsan laoreet sed ex. Suspendisse potenti. Suspendisse et orci euismod, hendrerit risus eget, suscipit mauris. Proin tincidunt tempor vestibulum. Curabitur quis nunc id ex finibus congue. Maecenas elementum placerat commodo. Nunc ultrices nisl sed leo faucibus, porttitor aliquet mauris tempor. Ut laoreet massa nisl, non hendrerit odio facilisis nec.</p>
        <img src={blog1}></img>
        <div>

        </div>
      </div>
  );
};

export default BlogDetail;
