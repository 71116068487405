import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import About from "./Pages/About/About";
import Services from "./Pages/Services/Services";
import Contact from "./Pages/Contact/Contact";
import Products from "./Pages/Products/Products";
import Partners from "./Pages/Partners/Partners";
import ProductDetail from "./Pages/ProductDetail/ProductDetail";

import DetailedPage from "./Pages/DetailedPage/DetailedPage.js";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Documents from "./Pages/Documents/Documents.js";
import Blogs from "./Pages/Blogs/Blogs.js";
import BlogDetail from "./Pages/BlogDetail/BlogDetail.js";
import Kataloq from "./Pages/Kataloq/Kataloq.js";
import ProductsCompany from "./Pages/ProductsCompany/ProductsCompany.js";

const Layout = () => {
  return ( 
    <div>
       <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover 
        theme="colored"
      /> 
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/blogs" element={<Blogs />}></Route>
        <Route path="/partners" element={<Partners />}></Route>
        <Route path="/documents" element={<Documents />}></Route>
        <Route path="/contact" element={<Contact />}></Route> 
        <Route path="/detailed-page" element={<DetailedPage />} />
        <Route path="/blogs/:id" element={<BlogDetail />} />

        <Route path="/products" element={<Products />}></Route>
        <Route path="/:name/company" element={<ProductsCompany />}></Route>
        <Route path="/:name/company/:brand" element={<Kataloq />}></Route>
        <Route path="/:name/company/:brand/:id" element={<ProductDetail />}></Route>
      </Route>
    </Route>
  )
);

function App() {

  return (
    <div className="">
      <div className="font-bodyFont">
      <div>
    <FloatingWhatsApp phoneNumber='+994503407080' accountName="Agrogurama" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' />
      <RouterProvider router={router} />
      </div>
    </div>
    </div>
  );
}

export default App;