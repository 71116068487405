import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, blog1, blog2, blog3, blog4, blog5, blog6, cardReader, dataTerminal, pulQutulari } from '../../assets/index';
import { useTranslation } from 'react-i18next';


export const products = [
  { 
    image: blog1,
    name: "Qusar 2023 Toxum Sərgi-Satış Yarm...",
    path: "/blogs/1",
    content: "Qusar 2023 Toxum Sərgi-Satış Yarmarkası kənd təsərrüfatı sahəsində sənaye liderlərini, yenilikçiləri və yerli fermerləri bir araya gətirərə..."
  },
  {
    image: blog2,
    name: "Təsərrüfatınıza uyğun traktorlar",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the..."
  },
  {
    image: blog3,
    name: "Düzgün suvarma sisteminin qurulm...",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog4,
    name: "Kartof əkini və əkində texnikanın rolu",
    path: "/blogs/4",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the..."
  },
  {
    image: blog5,
    name: "Təsərrüfatınıza uyğun traktorlar",
    path: "/blogs/5",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog6,
    name: "Düzgün suvarma sisteminin qurulm...",
    path: "/blogs/6",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },



];

const Blogs = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className="mt-[40px] md:max-w-container max-w-containerSm mx-auto mb-[132px]">
         <div className='flex flex-col justify-center items-center mb-5 '>
         <button className="w-[88px] bg-[#d4d64f] rounded-[32px] text-[white]">Bloq</button>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center">Ən son bloqlarımız</p>
         </div>
        <div>
      <div className='grid md:grid-cols-3 grid-cols-1 md:gap-10 gap-4'>
        {products.map((val, index) => {
          const { image, category, name, path, content} = val;
          return (
           <a href={path}>
             <div  data-aos="fade-up" className='bg-[#f5f9ee] rounded-[20px] md:h-[406px] pt-1' key={index}>
              
              <div className='mt-3 mb-5 ml-4 mr-4'>     
               <img className='rounded-lg h-[197px]' src={image} alt={name} />
               <div className='text mt-5'>
                 <h4 className='text-black text-base md:text-xl font-medium mb-2'>{t(name)}</h4>
                 <h4 className='text-[#b7b7b7] text-[14px] md:text-base font-normal mb-2'>{t(content)}</h4>
                <button className="rounded-[14px] border border-[#0d4b2c] w-36 h-10 mb-[12px]">
  Daha ətraflı
  </button>
               </div>
              </div>
             </div>
           </a>
          );
        })}
      </div>

        </div>
      </div>
  );
};

export default Blogs;