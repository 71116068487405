import React, { useEffect, useState } from "react";
import Heading from "../../Components/home/HeadingProducts";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { products1 } from "../../assets";
import Modal from "./Modal"; // Import the Modal component
import { toast } from "react-toastify";

export default function ProductDetail() {
 
  const { t, i18n } = useTranslation();

  const [products, setProducts] = useState([])
  const [allProducts, setallProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [category, setCategory] = useState('')
  const { name, brand, id } = useParams();
  const [selectedTab, setSelectedTab] = useState('description');
  

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const navigate = useNavigate()


  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.agrogurama.az/agrogurama/products.php?category=${encodeURIComponent(name)}`,
        { withCredentials: false }
      );
  
        const filteredProducts = response.data.data.filter(product => product.brand == brand && product.id == id);
        const filteredProducts2 = response.data.data.filter(product => product.brand == brand).slice(0, 4);

        setProducts(filteredProducts[0]);
        setallProducts(filteredProducts2);
        setIsLoading(false)
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if(name == 'traktor') {
     setCategory("Traktorlar")
    }
    if(name == 'aqreqat') {
      setCategory("Aqreqatlar")
     }
     if(name == 'land') {
      setCategory("Torpaq işləmə avadanlığı")
     }

     if(name == 'garden') {
      setCategory("Bağçılıq")
     }
     if(name == 'harvest') {
      setCategory("Məhsul yığım avadanlığı")
     }
     if(name == 'press') {
      setCategory("Pressbağlayıcı")
     }
     if(name == 'dirmiq') {
      setCategory("Dırmıq")
     }
     if(name == 'otbicen') {
      setCategory("Otbiçən")
     }
     if(name == 'kombayn') {
      setCategory("Kombayn")
     }
  }, [])


  const handleSubmitOrder = async () => {
  

    let inputs = {name:  clientName, email: clientEmail, phone: clientPhone, address: clientAddress, product: products.name}
    try {
      // Seçilen ürünleri post isteği ile gönder
      axios.post("https://www.agrogurama.az/agrogurama/orders.php/", inputs)
      .then(function (response) { 
        navigate("/");  
        setclientName('')
        setclientEmail('')
        setclientPhone('')
        setclientAddress('')

        // console.log(inputs);
      });
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Sifarişiniz qeydə alındı");
      setIsModalOpen(false);
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
    }
  };

  return (
    <>
          <div className="mt-[40px] max-w-containerSm md:max-w-container mx-auto mb-[40px] md:mb-[132px]">
         <div className="md:flex">
     <div className="md:mr-[52px]">
     <p className="md:hidden text-[22px] md:text-[32px] font-medium md:mb-6 mb-2">{products.name}</p>
     <p className="md:hidden">Kateqoriya: <span className="h-[28px] rounded-[20px] bg-[#B0D896] text-white px-[5px] md:px-[10px] text-base md:mr-3">{category}</span> İstehsalçı: <span className="h-[28px] rounded-[20px] bg-[#B0D896] text-white px-[10px] text-base">{products.brand}</span></p>
   <img src={`https://www.agrogurama.az/agrogurama/${products.image}`} className="md:w-[476px] mt-4 md:mt-0"></img>
     </div> 
     <div className="md:w-[597px]">
     <p className="hidden md:block text-[22px] md:text-[32px] font-medium mb-6">{products.name}</p>
      <p className="hidden md:block">Kateqoriya: <span className="h-[28px] rounded-[20px] bg-[#B0D896] text-white px-[5px] md:px-[10px] text-base md:mr-3">{category}</span> İstehsalçı: <span className="h-[28px] rounded-[20px] bg-[#B0D896] text-white px-[10px] text-base">{products.brand}</span></p>
      <p className="mt-5 mb-5">{products.shortDescription}</p>
      <p>Texniki göstəricilər:</p>
      {products.setting1 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting1}</span> <span>{products.answer1}</span></p> : ""}
      {products.setting2 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting2}</span> <span>{products.answer2}</span></p> : ""}
      {products.setting3 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting3}</span> <span>{products.answer3}</span></p> : ""}
      {products.setting4 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting4}</span> <span>{products.answer4}</span></p> : ""}
      {products.setting5 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting5}</span> <span>{products.answer5}</span></p> : ""}
      {products.setting6 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting6}</span> <span>{products.answer6}</span></p> : ""}
      {products.setting7 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting7}</span> <span>{products.answer7}</span></p> : ""}
      {products.setting8 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting8}</span> <span>{products.answer8}</span></p> : ""}
      {products.setting9 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting9}</span> <span>{products.answer9}</span></p> : ""}
      {products.setting10 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting10}</span> <span>{products.answer10}</span></p> : ""}
      {products.setting11 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting11}</span> <span>{products.answer11}</span></p> : ""}
      {products.setting12 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting12}</span> <span>{products.answer12}</span></p> : ""}
    <button onClick={() => setIsModalOpen(true)} className="mt-5 rounded-[14px] h-[40px] w-[154px] bg-[#0D4B2C] text-[white] mb-5">Maraqlanıram</button>
     </div>
         </div>  
         <div>
      {/* Üst kısım - Text butonları */}
      <div className="flex gap-4 mb-7">
        <button
          className={`px-4 py-2 ${
            selectedTab === 'description' ? 'border-black border-b-2' : 'text-[#B8B8B8]'
          }`}
          onClick={() => setSelectedTab('description')}
        >
          Məhsulun təsviri
        </button>
        <button
          className={`px-4 py-2 ${
            selectedTab === 'technical' ? 'border-black border-b-2' : 'text-[#B8B8B8]'
          }`}
          onClick={() => setSelectedTab('technical')}
        >
          Texniki göstəricilər
        </button>
      </div>

      {/* Alt kısım - Seçime göre içerik */}
      <div >
        {selectedTab === 'description' ? (
          <div>
            <p>{products.description}</p>
          </div>
        ) : (
          <div>
            <div className="md:w-[597px]">
      {products.setting1 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting1}</span> <span>{products.answer1}</span></p> : ""}
      {products.setting2 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting2}</span> <span>{products.answer2}</span></p> : ""}
      {products.setting3 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting3}</span> <span>{products.answer3}</span></p> : ""}
      {products.setting4 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting4}</span> <span>{products.answer4}</span></p> : ""}
      {products.setting5 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting5}</span> <span>{products.answer5}</span></p> : ""}
      {products.setting6 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting6}</span> <span>{products.answer6}</span></p> : ""}
      {products.setting7 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting7}</span> <span>{products.answer7}</span></p> : ""}
      {products.setting8 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting8}</span> <span>{products.answer8}</span></p> : ""}
      {products.setting9 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting9}</span> <span>{products.answer9}</span></p> : ""}
      {products.setting10 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting10}</span> <span>{products.answer10}</span></p> : ""}
      {products.setting11 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting11}</span> <span>{products.answer11}</span></p> : ""}
      {products.setting12 ?  <p><span className="text-[#B8B8B8] w-[250px]  inline-block mr-10">{products.setting12}</span> <span>{products.answer12}</span></p> : ""}
     </div>
          </div>
        )}
      </div>
    </div>
    </div>
    <div className="md:max-w-container max-w-containerSm mx-auto mb-[40px] md:mb-[132px]">
         <div className='flex flex-col justify-center items-center mb-5 md:mb-10'>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center">Bənzər məhsullar</p>
         </div>
        <div>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
        {allProducts.map((val, index) => { 
          const { image, category, name, path, content, id, shortDescription} = val;
          console.log(id)
          return (
            <a href={`/${category}/company/${brand}/${id}`}>
              <div className='bg-[#f5f9ee] rounded-[20px] h-[291px] ' key={index}>
              
              <div className='pt-3 pb-5 pl-4 pr-4'>      
              <div className='h-[171px] md:w-[266px] bg-[white] rounded-lg flex justify-center items-center'>
              <img className='rounded-lg h-[171px]' src={`https://www.agrogurama.az/agrogurama/${image}`} alt={name} />
              </div>
               <div className='text mt-3'>
                 <h4 className='text-black text-base font-medium mb-2'>{t(name)}</h4>
                 <h4 className='text-[black] text-sm font-normal'>{t(shortDescription)}</h4>
               </div>
              </div>
             </div> 
            </a>
          ); 
        })}
      </div>

        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="flex justify-between items-center mb-4 ">
            <h2 className="text-lg font-medium text-[black] ">Sizin məlumatlarınız</h2>
            <button onClick={() => setIsModalOpen(false)} className="text-[black] hover:text-gray-800">
              &#10005;
            </button>
          </div>
          <form onSubmit={handleSubmitOrder}>
            <div className="mb-4">
              <label className="block text-[black]">Ad, soyad*</label>
              <input type="text" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#F4F4F4]" 
               placeholder="Adınızı daxil edin"
               onChange={(event) => setclientName(event.target.value)}
               value={clientName}
               required
                />
               
            </div>
            <div className="mb-4">
              <label className="block text-[black]">E-mail*</label>
              <input type="email" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#F4F4F4]" 
              placeholder="Emailinizi daxil edin"
              onChange={(event) => setclientEmail(event.target.value)}
              value={clientEmail}
              required /> 
            </div>
            <div className="mb-4">
              <label className="block text-[black]">Əlaqə nömrəsi*</label>
              <input type="number" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#F4F4F4]" 
              placeholder="Telefon nömrənizi daxil edin"
              onChange={(event) => setclientPhone(event.target.value)}
              value={clientPhone}
              required /> 
            </div>
            <div className="mb-4">
              <label className="block text-[black]">Ünvan*</label>
              <input type="text" className="border rounded-[12px] w-full h-[40px] pl-3 bg-[#F4F4F4]"  
              placeholder="Ünvanınızı daxil edin"
              onChange={(event) => setclientAddress(event.target.value)}
              value={clientAddress}
              required /> 
            </div>
            <div className="flex justify-center">
              <button  type="submit" className="h-[40px] pl-3 bg-[#0D4B2C] text-white rounded-[12px] w-full">Təsdiqlə</button>
            </div>
          </form>
      </Modal>  
    </>
  );
}

