import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, contactHome, dataTerminal, hero, pulQutulari } from '../../../assets/index';
import { useTranslation } from 'react-i18next';


export const products = [
  {
    image: pulQutulari,
    name: "Bio-L birtərəfli",
    path: "/products/1",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: allInOne,
    name: "Bio-L birtərəfli",
    path: "/products/2",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: barkodProcessor,
    name: "Bio-L birtərəfli",
    path: "/products/3",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },

  {
    image: cardReader,
    name: "Bio-L birtərəfli",
    path: "/products/4",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  }

];

const Contact = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return ( 
   <>
    <div className="md:mt-[90px] mt-[40px] max-w-containerSm md:max-w-container mx-auto">
         <div className='flex flex-col justify-center items-center mb-5'>
         <button className="w-[88px] bg-[#d4d64f] rounded-[32px] text-[white]">Əlaqə</button>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center">Bizimlə əlaqə saxlayın</p>
         <p className='text-center text-black text-lg font-normal'>Köməyə ehtiyacınız var? Sual və təkliflərinizi bizə söyləyə bilərsiniz.</p>
         </div>
      <div>
 <div className='flex flex-col-reverse md:flex-row  mt-[40px] mb-[40px] md:mb-[158px] justify-between '>
 <div className="relative md:w-[458px] flex flex-col justify-between mt-5 md:mt-0">
  <img src={contactHome} alt="Contact" className="w-full h-auto rounded-[8px] md:rounded-[0px]" />
  <div className="absolute bottom-6 left-4 flex space-x-4">
    <div className='flex gap-2'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_b_208_388)">
<path d="M12.0273 0H11.9727C5.36035 0 0 5.36035 0 11.9727V12.0273C0 18.6397 5.36035 24 11.9727 24H12.0273C18.6397 24 24 18.6397 24 12.0273V11.9727C24 5.36035 18.6397 0 12.0273 0Z" fill="#282828"/>
</g>
<path d="M15.7134 4.88281H8.28711C6.23547 4.88281 4.56641 6.55187 4.56641 8.60351V15.3975C4.56641 17.4491 6.23547 19.1182 8.28711 19.1182H15.7134C17.765 19.1182 19.4341 17.4491 19.4341 15.3975V8.60351C19.4341 6.55187 17.765 4.88281 15.7134 4.88281ZM5.87895 8.60351C5.87895 7.27583 6.95943 6.19536 8.28711 6.19536H15.7134C17.0411 6.19536 18.1215 7.27583 18.1215 8.60351V15.3975C18.1215 16.7252 17.0411 17.8056 15.7134 17.8056H8.28711C6.95943 17.8056 5.87895 16.7252 5.87895 15.3975V8.60351Z" fill="white"/>
<path d="M12.0002 15.4603C13.9081 15.4603 15.4611 13.9082 15.4611 11.9995C15.4611 10.0908 13.9089 8.53857 12.0002 8.53857C10.0915 8.53857 8.53931 10.0908 8.53931 11.9995C8.53931 13.9082 10.0915 15.4603 12.0002 15.4603ZM12.0002 9.85196C13.1849 9.85196 14.1485 10.8156 14.1485 12.0003C14.1485 13.185 13.1849 14.1486 12.0002 14.1486C10.8155 14.1486 9.85185 13.185 9.85185 12.0003C9.85185 10.8156 10.8155 9.85196 12.0002 9.85196Z" fill="white"/>
<path d="M15.7816 9.09789C16.2954 9.09789 16.7141 8.67999 16.7141 8.1654C16.7141 7.65081 16.2962 7.23291 15.7816 7.23291C15.267 7.23291 14.8491 7.65081 14.8491 8.1654C14.8491 8.67999 15.267 9.09789 15.7816 9.09789Z" fill="white"/>
<defs>
<filter id="filter0_b_208_388" x="-4" y="-4" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_208_388"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_208_388" result="shape"/>
</filter>
</defs>
</svg>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_b_208_394)">
<path d="M23.9983 12.0004C23.9983 18.0612 19.5059 23.0717 13.6698 23.8848C13.1242 23.9605 12.5659 24 11.9992 24C11.345 24 10.7026 23.9479 10.0771 23.847C4.36379 22.9271 0 17.9729 0 12.0004C0 5.37294 5.37276 0 12 0C18.6272 0 24 5.37294 24 12.0004H23.9983Z" fill="#282828"/>
</g>
<path d="M13.7878 9.47496V12.1184H17.2491L16.701 15.6793H13.7878V23.8835C13.2037 23.96 12.6061 24 11.9996 24C11.2994 24 10.6118 23.9473 9.94221 23.8453V15.6793H6.75V12.1184H9.94221V8.88403C9.94221 6.87741 11.6639 5.25 13.7887 5.25V5.2517C13.795 5.2517 13.8004 5.25 13.8067 5.25H17.25V8.32966H15.0001C14.3314 8.32966 13.7887 8.84237 13.7887 9.47411L13.7878 9.47496Z" fill="white"/>
<defs>
<filter id="filter0_b_208_394" x="-4" y="-4" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_208_394"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_208_394" result="shape"/>
</filter>
</defs>
</svg>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0273 0H11.9727C5.36034 0 0 5.36035 0 11.9727V12.0273C0 18.6397 5.36034 24 11.9727 24H12.0273C18.6396 24 24 18.6397 24 12.0273V11.9727C24 5.36035 18.6396 0 12.0273 0Z" fill="#282828"/>
<path d="M15.9541 19.875H13.3239C10.9553 19.875 9.19037 18.6566 9.19037 15.7414V11.0731H7.03784V8.54555C9.40648 7.9309 10.397 5.89187 10.5113 4.12695H12.9708V8.13523H15.8406V11.0731H12.9708V15.1386C12.9708 16.3569 13.5854 16.7782 14.565 16.7782H15.9541V19.8758V19.875Z" fill="white"/>
</svg>
    </div>
  </div>
</div>

<div className='md:w-[742px]'>
<form class="space-y-8">
    <div class="grid grid-cols-1 md:gap-6 gap-4"> 
      <div>
        <label for="company" class="block text-base font-medium text-[black]">Ad, soyad</label>
        <input type="text" id="company" class="bg-neutral-100 mt-2 block w-full h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3" />

      </div>
    </div>
    
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6"> 
      <div>
        <label for="quantity" class="block text-base font-medium text-[black]">Əlaqə nömrəsi</label>
        <input type="number" id="quantity" class="bg-neutral-100 mt-2  block w-full h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3 "></input>
      </div>
      <div>
        <label for="email" class="block text-base font-medium text-[black]">E-mail</label>
        <input type="email" id="email" class="bg-neutral-100 mt-2 block w-full h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3 " ></input>
      </div>
    </div>

    <div>
      <label for="message" class="block text-base font-medium text-[black]">Mesajınız</label>
      <textarea id="message" rows="4" class="bg-neutral-100 h-36 pl-3 pr-3 pt-3 mt-2 block w-full border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" ></textarea>
    </div>

    <div class="flex justify-start">
      <button type="submit" class="w-[148px] h-10 p-2.5 bg-[#0d4b2c] rounded-xl justify-center items-center gap-2.5 inline-flex text-[white]">Göndər</button>
    </div>
  </form>
 </div>
 </div>
  </div>
  </div>
   </>
  );
};

export default Contact;