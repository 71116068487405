import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import { useTranslation } from "react-i18next";
import { about1, about2, about3, hero, kataloq1, values1, values2, values3, values4 } from "../../assets";

export default function About() {

  const { t, i18n } = useTranslation();
  return (
    <>
       <div className="md:max-w-container max-w-containerSm mt-[40px] mx-auto mb-[40px] md:mb-[132px]">
               <div className='flex flex-col justify-center items-center'>
         <button className="w-[120px] bg-[#d4d64f] rounded-[32px] text-[white]">Haqqımızda</button>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center">Kənd Təsərrüfatı Sektorunda Artan Trend</p>
         </div>
  <div className="flex flex-col md:flex-row justify-center items-center md:mt-[40px] mt-[20px] ">
   <div className="md:w-1/2 rounded-[14px] "><img className="rounded-[12px] md:rounded-[12px] mb-[20px] md:mb-[0px]" src={about1}></img></div>
<div className="md:w-1/2  md:pl-[32px]">
<p className="text-[#0d4b2c] text-[18px] md:text-[32px] font-medium mb-[16px] md:mb-[22px]">Biz Kimik</p>
<p className="text-black text-sm font-normal">“AGRO GURAMA”MMC Şirkəti 2022ci ildən fəaliyyət göstərir və kənd  təsərüffatı texnikaları təhcizatçıları sırasında önəmli mövqe tutmuşdur. Biz fermerlərin istəklərinə uyğun olaraq zamanın tələblərinə cavab verən müasir innovativ texnikaları fermerlərin və təsərüffatların əlçatanlığını təmin etmək məqsədi ilə ölkəmizə idxalını təmin edirik. Şirkətimiz kənd təsərüffatında müasirləşmə dinamikasını, həmçinin məhsuldar kənd təsərrüfatına artan tələbatı nəzərə alaraq, xarici istehsalçı şirkətlərlə əməkdaşlıq edir.  Buna misal olaraq Almaniyanın nəhəng konsernlərindən olan KRONE , LEMKEN , AMAZONE, brendlərinin Azərbaycanda rəsmi distribüteri təyin edilmişdir. Eyni zamanda İtaliya brendi olan Ferrari traktorlarının, İspaniyanın bağçılıq aqreqatları sahəsində ən öndə olan İD-DAVİD brendinin Azərbaycandakı rəsmi distribüterli kimi illərdir uğurla fəaliyət göstəririk. Şirkətimiz qurulduğu tarixdən etibarən  fəaliyət itiqamətinə Satış öncəsi düzgün konsultasiya, satış sonrası servis və ehtiyat hissələrinin təmini,  daim diqqət yetirmişdir. Bununlada Fermerlərimizə texnika satışından öncə və texnika satıldıqdan sonra daim yanlarında olduğuzun qarantını veririk. 2024cü ilin may ayında “AGROGURAMA” şirkəti olaraq ilk dəfə Bakı Expo Mərkəzində keçirilən “CASPİAN AGRO” sərgisində uğurlu bir şəkildə iştirak etmiş həmin sərgidə Nümayiş etdirdiyi KRONE, LEMKEN, FERRARİ AMAZONE və İD-DAVİD brendlərinin məhsulları ilə sahibkarları və sahə peşəkarlarının diqqətini cəlb edərək tələblərinə cavab vermişdir.</p>
</div>
   </div>
   <div className="flex flex-col-reverse md:flex-row justify-center items-center md:mt-[40px] mt-[20px]">
<div className="md:w-1/2  md:pr-[32px]">
<p className="text-black text-sm font-normal">Hal -hazırda şirkətimizin Göyçay rayonu Qaraməryəm ərazisində müasir tələblərə cavab verən ehtiyat hissələri anbarı , təmir bazası  və satış məntəqəsi mövcuddur. 7/24 prinsipi ilə çalışan Səyyar servis briqadalarımızla daima fermerlərin problemlərinin operativ həllini icra edirik. Şirkətimizin qarşıdakı illər ərzində əsas missiyası dünyada ərzaq təhlükəsizliyinin təminatı üçün mərkəzi Qarabağ İqtisadi Bölgəsində olacaq hal hazırda və gələcəkdə ölkəmizdə satışını icra ettiyimiz hər bir brendin texnikalar və avadanlıqları üçün təlimlərin keçirilməsi məqsədi ilə inşa ediləcək təlim mərkəzini fəaliyətini təmin edərək hər bir sahibkar fermer və sahə peşəkarlarının bilik və bacarıqlarını dahada təkmilləşdirərək istifadə etmiş olduqları texnika avadanlıqlardan tam doğru və səmərəli şəkildə istifadə etmələrinə şərait yaratmaqdır. Bu təlim mərkəzi eyni zamanda qarşıdan gələn gələcək nəsillərin kənd təsərüfatı üzrə peşə ixtisalarını dahada dərindən öyrənməsi üçün olduqca önəmli bir layihədir. Eyni zamanda şirkətimizin təşəbbüsü ilə kənd təsərüffatı üzrə Azərbaycanda artan kadr ehtiyacının təmin edilməsi məqsədi üçün kənd təsərüffatı üzrə beynəlxalq  üniversitetin regional filialının açılacaqdır.</p>
</div>
<div className="md:w-1/2 mb-[20px] md:mb-[0px]"><img src={about2}></img></div>
   </div>
   <div className="flex flex-col md:flex-row  justify-center items-center mt-[20px] md:mt-[40px]">
   <div className="md:w-1/2"><img className="rounded-[12px] md:rounded-[12px] mb-[20px] md:mb-[0px]" src={about3}></img></div>
<div className="md:w-1/2  md:pl-[32px]">
<p className="text-[#0d4b2c] text-[18px] md:text-[32px] font-medium mb-5">Dəyərlərimiz</p>
<p className=" text-black text-sm font-normal">
<div className="flex md:h-20 mb-4 md:mb-0">
<img className="h-[40px] w-[40px]" src={values1}></img>
<div className="flex flex-col ml-3">
<p className="text-black text-base md:text-lg font-medium">Etibarlılıq</p>
<p className="text-black text-sm font-normal">Müştərilərimizə etibarlı və uzun ömürlü məhsul təmin edərək, onların iş proseslərini dəstəkləmək.</p>
</div>
 </div>
 <div className="flex md:h-20 mb-4 md:mb-0">
 <img className="h-[40px] w-[40px]" src={values2}></img>
<div className="flex flex-col ml-3">
<p className="text-black text-lg font-medium">Keyfiyyət</p>
<p className="text-black text-sm font-normal">Yüksək keyfiyyətli aqreqat məhsulları təqdim edərək müştəri tələblərinə ən yaxşı şəkildə cavab vermək.</p>
</div>
 </div>
 <div className="flex md:h-20 mb-4 md:mb-0">
 <img className="h-[40px] w-[40px]" src={values3}></img>
<div className="flex flex-col ml-3">
<p className="text-black text-lg font-medium">İnnovasiya</p>
<p className="text-black text-sm font-normal">Texnoloji inkişafları izləyərək, kənd təsərrüfatı sektorunda ən müasir həlləri təqdim etmək.</p>
</div>
 </div>
 <div className="flex md:h-20">
 <img className="h-[40px] w-[40px]" src={values4}></img>
<div className="flex flex-col ml-3">
<p className="text-black text-lg font-medium">Müştəri məmnuniyyəti</p>
<p className="text-black text-sm font-normal">Müştəri ehtiyaclarını diqqətlə dinləyərək, fərdi yanaşma ilə xidmət göstərmək.</p>
</div>
 </div>
</p>
</div>
   </div>
     </div>
    </>
  );
}
