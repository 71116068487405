import React from 'react';
import { footerLogo, hero } from '../../assets';

const Footer = () => {
  return (
    <footer className="bg-[#F1F1F1] text-gray-800 md:py-8">
      <div className="md:max-w-container max-w-containerSm mx-auto md:flex justify-between pt-[32px] md:pt-0">
        <div className='md:w-[310px]'>
          <h3 className="text-xl font-semibold md:mb-2"><img className="w-[143px] h-[91px]" src={footerLogo}></img></h3>
          <p className='text-sm md:text-base'>Kənd Təsərrüfatında Məhsuldarlığınızı Yüksəltmək üçün Yüksək Keyfiyyətli və Etibarlı İnnovativ Həlləri</p>
        </div>
        <div className='w-[130px] mt-5 md:mt-0'> 
          <h4 className="text-lg font-semibold mb-2">Əsas keçidlər</h4>
          <ul >
            <li className='mb-2'><a href="/" className="hover:underline mb-2">Ana səhifə</a></li>
            <li className='mb-2'><a href="/products" className="hover:underline mb-2">Məhsullar</a></li>
            <li className='mb-2'><a href="/about" className="hover:underline  mb-2">Haqqımızda</a></li>
            <li className='mb-2'><a href="/services" className="hover:underline  mb-2">Xidmətlər</a></li>
            <li className='mb-2'><a href="/blogs" className="hover:underline  mb-2">Bloq</a></li>
            <li><a href="/contact" className="hover:underline">Əlaqə</a></li>
          </ul>
        </div>
        <div className='md:w-[342px] mt-4 md:mt-0'>
          <div>
          <h4 className="text-lg font-semibold mb-2">Əlaqə</h4>
          <div className='flex gap-2 mb-[29px]'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#282828"/>
<path d="M10.5282 6.98713L11.015 7.85933C11.4542 8.64644 11.2779 9.67899 10.586 10.3708C10.586 10.3709 10.586 10.3708 10.586 10.3709C10.5859 10.371 9.74694 11.2102 11.2684 12.7316C12.7892 14.2524 13.6283 13.4148 13.6291 13.414C13.6292 13.4139 13.6292 13.414 13.6292 13.4139C14.321 12.7221 15.3536 12.5458 16.1407 12.985L17.0129 13.4718C18.2014 14.1351 18.3418 15.8019 17.2971 16.8466C16.6693 17.4744 15.9003 17.9629 15.0502 17.9951C13.6191 18.0493 11.1887 17.6872 8.75078 15.2492C6.31285 12.8113 5.95066 10.3809 6.00491 8.94982C6.03714 8.0997 6.5256 7.33068 7.15335 6.70292C8.19807 5.65821 9.86488 5.79858 10.5282 6.98713Z" fill="#282828"/>
</svg>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="12" fill="#B0D896"/>
<path d="M8.50356 5.5H15.4964C17.1319 5.5 18.5 6.89691 18.5 8.65919V15.3408C18.5 17.1031 17.1319 18.5 15.4964 18.5H8.50356C6.86805 18.5 5.5 17.1031 5.5 15.3408V8.65919C5.5 6.89691 6.86805 5.5 8.50356 5.5ZM8.50356 5.79084C6.95697 5.79084 5.73595 7.09802 5.73595 8.65919V15.3408C5.73595 16.902 6.95697 18.2092 8.50356 18.2092H15.4964C17.043 18.2092 18.2641 16.902 18.2641 15.3408V8.65919C18.2641 7.09802 17.043 5.79084 15.4964 5.79084H8.50356Z" fill="#0D4B2C" stroke="white"/>
<path d="M14.759 11.9984C14.759 13.62 13.5003 14.9021 12.0001 14.9021C10.4992 14.9021 9.24121 13.6201 9.24121 11.9984C9.24121 10.3767 10.4992 9.09473 12.0001 9.09473C13.501 9.09473 14.759 10.3767 14.759 11.9984ZM14.5231 11.9992C14.5231 10.5786 13.4121 9.3864 12.0001 9.3864C10.5881 9.3864 9.47716 10.5786 9.47716 11.9992C9.47716 13.4198 10.5881 14.612 12.0001 14.612C13.4121 14.612 14.5231 13.4198 14.5231 11.9992Z" fill="#0D4B2C" stroke="white"/>
<path d="M15.9388 8.2286C15.9388 8.47894 15.7483 8.64567 15.5607 8.64567C15.3725 8.64567 15.1826 8.47924 15.1826 8.2286C15.1826 7.97795 15.3725 7.81152 15.5607 7.81152C15.7488 7.81152 15.9388 7.97795 15.9388 8.2286Z" fill="#0D4B2C" stroke="white"/>
</svg>

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#282828"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.37868 6.87868C4.5 7.75736 4.5 9.17157 4.5 12C4.5 14.8284 4.5 16.2426 5.37868 17.1213C6.25736 18 7.67157 18 10.5 18H13.5C16.3284 18 17.7426 18 18.6213 17.1213C19.5 16.2426 19.5 14.8284 19.5 12C19.5 9.17157 19.5 7.75736 18.6213 6.87868C17.7426 6 16.3284 6 13.5 6H10.5C7.67157 6 6.25736 6 5.37868 6.87868ZM16.9321 8.6399C17.131 8.87855 17.0988 9.23324 16.8601 9.43212L15.2127 10.8049C14.548 11.3589 14.0092 11.8079 13.5336 12.1138C13.0382 12.4324 12.5558 12.6337 12 12.6337C11.4442 12.6337 10.9618 12.4324 10.4664 12.1138C9.99084 11.8079 9.45203 11.3589 8.78727 10.8049L7.1399 9.43212C6.90124 9.23324 6.869 8.87855 7.06788 8.6399C7.26676 8.40124 7.62145 8.369 7.8601 8.56788L9.47928 9.91719C10.179 10.5003 10.6648 10.9038 11.0749 11.1676C11.472 11.4229 11.7412 11.5087 12 11.5087C12.2588 11.5087 12.528 11.4229 12.9251 11.1676C13.3352 10.9038 13.821 10.5003 14.5207 9.91718L16.1399 8.56788C16.3786 8.369 16.7332 8.40124 16.9321 8.6399Z" fill="#282828"/>
</svg>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0.5C18.2847 0.5 23.3923 5.54202 23.4983 11.8017V12.0004C23.4983 17.8081 19.1934 22.6103 13.6012 23.3895C13.078 23.4621 12.5426 23.5 11.9992 23.5C11.3716 23.5 10.7558 23.45 10.1567 23.3534L10.1566 23.3533C4.68178 22.4719 0.5 17.7236 0.5 12.0004C0.5 5.64907 5.64892 0.5 12 0.5Z" stroke="#282828"/>
<path d="M14.8024 8.00352C13.9772 8.00352 13.2946 8.6168 13.1858 9.41223L13.1698 9.4281V9.63613V12.2503V12.7503H13.6698H16.3256L15.959 15.2717H13.6698H13.1698V15.7717V23.4413C12.7849 23.4801 12.3942 23.5001 11.9992 23.5001C11.5174 23.5001 11.0427 23.4707 10.5771 23.4133V15.7717V15.2717H10.0771H7.59473V12.7503H10.0771H10.5771V12.2503V9.05175C10.5771 7.37789 11.9065 6.01405 13.5676 5.95969H13.6707C13.6867 5.95969 13.701 5.95896 13.713 5.95801H16.4044V8.00352H14.8024Z" fill="#282828" stroke="#282828"/>
</svg>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0264 24H11.9736C5.37142 24 0 18.6286 0 12.0264V11.9736C0 5.37142 5.37142 0 11.9736 0H12.0264C18.6286 0 24 5.37142 24 11.9736V12.0264C24 18.6286 18.6286 24 12.0264 24ZM11.9736 0.812375C5.81904 0.812375 0.812375 5.81904 0.812375 11.9736V12.0264C0.812375 18.181 5.81904 23.1876 11.9736 23.1876H12.0264C18.1809 23.1876 23.1876 18.181 23.1876 12.0264V11.9736C23.1876 5.81904 18.1809 0.812375 12.0264 0.812375H11.9736Z" fill="#282828"/>
<path d="M15.8207 19.6076H13.2796C10.9912 19.6076 9.28597 18.4304 9.28597 15.6139V11.1036H7.2063V8.66161C9.49476 8.06777 10.4517 6.09775 10.5622 4.39258H12.9384V8.26519H15.7111V11.1036H12.9384V15.0315C12.9384 16.2086 13.5323 16.6156 14.4787 16.6156H15.8207V19.6084V19.6076Z" fill="#282828"/>
</svg>
          </div>
        <h4 className="text-lg font-semibold mb-2">Təklifinizi yazın</h4>
 <div className='flex'>
 <textarea
    type="text"
    id="company"
    className="w-full h-10 p-2.5 bg-white rounded-l-xl border border-[#bcbcbc] justify-start items-center gap-2.5 inline-flex text-sm focus:outline-none transition-all duration-300 resize-none overflow-hidden"
    placeholder="Bura qeyd edin..."
    rows="1"
    maxLength={200} // Karakter sınırı burada
    onInput={(e) => {
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;

      // Karakter sınırı kontrolü
      if (e.target.value.length > 200) {
        e.target.value = e.target.value.slice(0, 200);
      }
    }}
  />
  <div className="w-[148px] h-10 p-2.5 bg-[#0d4b2c] rounded-r-xl justify-center items-center gap-2.5 inline-flex text-white text-sm">
    Göndər
  </div>
 </div>
</div>



        </div>
      </div>
      <div className='h-[1px] bg-[#B8B8B8] mt-5'></div>
      <div className="md:max-w-[1260px] max-w-containerSm md:flex justify-between mt-3 md:mt-8 md:text-center text-sm mx-auto pb-5 md:pb-0">
       <p> Agrogurama © Bütün hüquqlar qorunur.</p>
       <p> Sayt hazırlandı: Birsayt.az</p>
      </div>
    </footer>
  );
};

export default Footer;