import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header/Header'
import { certificate1, certificate2, certificate3, partners1, partners10, partners11, partners12, partners13, partners14, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, whyus, whyus2 } from '../../assets/index'
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ProductsCompany = () => {

  const [brands, setBrands] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { name } = useParams();


  useEffect(() => {
    getBrands();
  }, []);

  function getBrands() {
    axios
      .get("https://www.agrogurama.az/agrogurama/brands.php/", {
        withCredentials: false
    }) 
      .then(function (response) {
        const filteredProducts = response.data.filter(brand => brand.category == name);
        setBrands(filteredProducts);
        console.log(filteredProducts)
        setIsLoading(false)
      });
  }
  return (
    <>
    <div className="mx-auto pt-[40px] md:pb-[158px] pb-[40px]">
       <div className="md:max-w-container max-w-containerSm mx-auto md:h-[813px]">
       <div className='flex flex-col justify-center items-center mb-10'>
         <button className="w-[88px] bg-[#d4d64f] rounded-[32px] text-[white]">Məhsullar</button>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center">Mövcud şirkətlər</p>
         </div>
    <div className='text-[black] h-auto md:lg:h-[960px] mb-[40px] lg:mb-[283px]'>
      <div className='md:max-w-container max-w-containerSm lg: mx-auto'>
      <div className='md:h-[145px] grid md:grid-cols-4 grid-cols-1 gap-8'>
      {brands.map((item) => (
    <div key={item.id} className="border-2 md:w-[302px] md:h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]">
      <a href={`/${name}/company/${item.name}`}>
        <div className="w-[90%] mx-auto">
          <img className="h-[113px] object-contain" src={`https://www.agrogurama.az/agrogurama/${item.image}`} alt="Partner" />
        </div>
      </a>
    </div>
  ))
}

        </div>
      </div>
    </div>
     </div>
     
</div>
  
    
    </>
  )
}

export default ProductsCompany