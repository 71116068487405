import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, blog1, blog2, blog3, cardReader, dataTerminal, pulQutulari } from '../../../assets/index';
import { useTranslation } from 'react-i18next';


export const products = [
  {
    image: blog1,
    name: "Qusar 2023 Toxum Sərgi-Satış Yarm...",
    path: "/blogs/1",
    content: "Qusar 2023 Toxum Sərgi-Satış Yarmarkası kənd təsərrüfatı sahəsində sənaye liderlərini, yenilikçiləri və yerli fermerləri bir araya gətirərə..."
  },
  {
    image: blog2,
    name: "Təsərrüfatınıza uyğun traktorlar",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },
  {
    image: blog3, 
    name: "Düzgün suvarma sisteminin qurulm...",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men who are so beguiled demoralized by the charms of pleasure of the ..."
  },

];

const BlogsShort = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className="md:mt-[90px] mt-[40px] md:max-w-container max-w-containerSm mx-auto">
         <div className='flex flex-col justify-center items-center mb-10'>
         <button className="w-[88px] bg-[#d4d64f] rounded-[32px] text-[white]">Bloq</button>
         <p className="text-black text-[32px] font-medium text-cente ">Ən son bloqlarımız</p>
         </div>
        <div>
      <div className='grid md:grid-cols-3 grid-cols-1 gap-4 md:gap-10'>
        {products.map((val, index) => {
          const { image, category, name, path, content} = val;
          return (
             <div  data-aos="fade-up" className='bg-[#f5f9ee] rounded-[20px] md:h-[406px]' key={index}>
              
             <div className='mt-3 mb-5 ml-4 mr-4'>     
              <img className='rounded-lg h-[197px]' src={image} alt={name} />
              <div className='text mt-5'>
                <h4 className='text-black text-xl font-medium mb-2'>{t(name)}</h4>
                <h4 className='text-[#b7b7b7] text-base font-normal mb-2'>{t(content)}</h4>
               <a href={path}> <button className='w-36 h-10 rounded-[14px] border border-[#0d4b2c] mt-2'>Daha ətraflı</button></a>
              </div>
             </div>
            </div>
          );
        })}
      </div>
      <div className='mt-5 mx-auto flex justify-center'>
    <a href='/blogs'><p className='text-black text-base font-normal inline-block border-b border-black'>Hamısını gör</p></a>
</div>

        </div>
      </div>
  );
};

export default BlogsShort;

